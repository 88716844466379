<template>
  	<div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item text-secondary">Brand</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				<div class="col-md-12 mb-4">
					<div>
                        <div class="row">
                            <div class="col-md-12 mb-4">
                                <div class="card shadow border-0 round">
                                    <div class="card-body p-4">
                                        <div class="d-md-flex justify-content-between align-items-center">
                                            <!-- Mencetak deskripsi halaman berdasarkan halaman apa yang sedang dibuka -->
                                            <p class="my-0 text-muted mx-2 text-center">Menampilkan <span class="text-dark">{{ total_brand }} brand</span> terdapat di Dunia Murah.</p>
                                        </div>
                                        <hr />
                                        <!-- Jika isRefresh true -->
                                        <div class="text-center my-3" v-if="isRefresh">
                                            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <!-- Jika isRefresh false -->
                                        <div v-else>
                                            <!-- Jika terdapat produk -->
                                            <div v-if="total_brand > 0">
                                                <!-- Maka tampilkan produk -->
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-6 p-2" v-for="brand in brands" :key=brand.brand_id>
                                                        <div class="card product round overflow-hidden">
                                                            <!-- Route to detail produk jika image diklik -->
                                                            <router-link :to="'/product-list?type=product&q='+ brand.brand_name" class="text-decoration-none">
                                                                <div class="d-flex align-items-center" style="height: 208px; overflow: hidden;">
                                                                    <img v-lazy="manufactureImage(brand.brand_image)" @error="imageLoadError" class="card-img-top" :alt="brand.brand_image" />
                                                                </div>
                                                                <h6 style="max-height: 20px; overflow: hidden; text-align: center;" class="card-text text-secondary mb-0">{{ brand.brand_name }}</h6>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Pengaturan page bagian product -->
                                                <div class="row">
                                                    <div class="col-md-12 w-100 text-center">
                                                        <hr />
                                                        <b-pagination v-model="current_page" :per-page="per_page" @change="getBrands" :total-rows="total_brand" first-number last-number align="center" size="md"></b-pagination>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Jika tidak terdapat produk -->
                                            <div v-else>
                                                <!-- Maka cetak kalimat berikut -->
                                                <div class="text-center my-5">
                                                    <h4 class="text-info mb-1">Oops!</h4>
                                                    <p class="text-muted">Halaman yang Anda cari tidak ditemukan!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
            </div>
        </main>
    </div>
</template>

<script>

export default {
    data () {
        return {
            brands: [],
            total_brand: 0,
            current_page: this.$session.get('lastPageProductList') || 1,
			per_page: 0,
			isRefresh: false,
        }
    },
    methods: {
    	getBrands(current_page) {
			this.isRefresh = true
			this.total_product = 0
			window.axios.get('/brand?page='+ current_page)
			.then((response) => {
			this.isRefresh = false
			this.per_page = response.data.data.data.per_page
			this.brands = response.data.data.data
			let position = $(document).scrollTop()
			$('html, body').stop().animate({
				scrollTop: 83
			}, 1000, 'easeInOutExpo')
			})
			.catch((error) => {
				console.log(error)
			})
			this.current_page = current_page
		},
    },
    computed: {
        keyword: function () {
			return this.$route.query.q
		},
    },
    created () {
        if(this.keyword != this.$session.get('lastQueryProductList')) {
			this.current_page = 1
		}
        
        window.axios.get("/brand?page="+this.current_page)
        .then((response) => {
            this.brands = response.data.data.data;
            this.total_brand = response.data.data.total;
        })
        .catch((error) => {
        console.log(error);
        });

        this.$session.start()
		$('html, body').stop().animate({
			scrollTop: 83
		}, 1000, 'easeInOutExpo')
    },
    watch: {
        '$route.query.q': function () {
		
			if(this.keyword != this.$session.get('lastQueryProductList')) {
				this.current_page = 1
			}
            this.getBrands(this.current_page)
        }
    }
}
</script>